















import { Component, Vue } from "vue-property-decorator";
import ClickOutside from "vue-click-outside";

@Component({
  directives: {
    ClickOutside,
  },
})
export default class DropdownOptions extends Vue {
  open = false;
}
